import ProgressiveImage from "react-progressive-graceful-image";
import React from "react";
import "./ProgressiveImageCustom.css"

export default function ProgressiveImageCustom({ src }) {
  return (
    <div className="progressive-image-container">
      <ProgressiveImage delay={1000} src={src} placeholder={src}>
        {(src, loading) => (
          <img
            className="prgressive-image"
            src={src}
            alt=""
            style={{
              objectFit: "cover", // Ensure cropping without distortion
              visibility: loading ? "hidden" : "visible", // Avoid flashing placeholder
            }}
          />
        )}
      </ProgressiveImage>
    </div>
  );
}
