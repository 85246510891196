import React, { useEffect } from "react";
import dynamicsImage from "../Media/service1_dynamics365.png";
import businessCentral from "../Media/service1_business_central.png";
import customerInsights from "../Media/service1_customer_insights.png";
import fieldService from "../Media/service1_field_service.png";
import finance from "../Media/service1_finance.png";
import Banner from '../Components/Banner.tsx';
import { useNavigate } from "react-router-dom";
import ProgressiveImageCustom from "../Components/ProgressiveImageCustom.tsx";

export default function MicrosoftDynamics365() {

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      },
      { threshold: 0.1 } // Trigger when 50% of the element is visible
    );

    const items = document.querySelectorAll(".services-list li");
    items.forEach((item) => observer.observe(item));

    return () => observer.disconnect();
  }, []);

  const handleInquiry = (featureName) => {
    navigate("/inquiry", { state: { preselectedTopic: featureName } });
  };


  return (
    <div className="service">
      <div className="page-content">
        <Banner
          image={dynamicsImage}
          textTop="Stärken Sie Ihr Unternehmen"
          textBottom="Mit Microsoft Dynamics 365"
          textColor="#ffffff"
        />
        <section className="it-partner-section">
          <h1>Warum Microsoft Dynamics 365?</h1>
          <p>
            Microsoft Dynamics 365 ist eine Suite intel&shy;ligenter Business-Anwen&shy;dungen, die Unter&shy;nehmen dabei unter&shy;stützt,
            operative Exzel&shy;lenz zu erzielen und fesselnde Kunden&shy;erleb&shy;nisse zu schaffen. Die Plattform vereint naht&shy;los
            CRM- und ERP-Funk&shy;tionen, um Effi&shy;zienz, Skalier&shy;barkeit und Inno&shy;vation zu fördern. Mit Dynamics 365 können
            Unter&shy;nehmen ihre Daten nutzen, um Pro&shy;zesse zu opti&shy;mieren und fundierte Entschei&shy;dungen zu treffen.
          </p>

          <br></br>
          <ul className="services-list">

            <li>

              <div className="list-title">
                <strong>Business Central - Opti&shy;mieren Sie Ihr Unter&shy;nehmen mit einer umfas&shy;senden Geschäfts&shy;lösung</strong>
              </div>
              <ProgressiveImageCustom src={businessCentral} />
              <div className="list-description">
                <p>
                  Ver&shy;bessern Sie Ihre Finanzen, Ver&shy;k&auml;ufe, Services und Betriebs&shy;abl&auml;ufe mit einer speziell
                  für kleine bis mittel&shy;st&auml;ndische Unter&shy;nehmen entwickelten L&ouml;sung. Dynamics 365 unter&shy;stützt
                  Sie dabei, Ihre Pro&shy;zesse zu opti&shy;mieren und daten&shy;basierte Entschei&shy;dungen schneller und
                  pr&auml;ziser zu treffen.
                </p>
                <ul>
                  <li>
                    <strong>Echtzeit-Ein&shy;blicke:</strong> Nutzen Sie aktuelle Daten und Ana&shy;lysen, um Trends zu erkennen und Ihre Geschäfts&shy;strategie anzu&shy;passen.
                  </li>
                  <li>
                    <strong>Pr&auml;zise Bericht&shy;erstellung:</strong> Erstellen Sie detail&shy;lierte Berichte, die Ihre Leistungs&shy;kennzahlen (KPIs) klar und ver&shy;st&auml;ndlich darstellen.
                  </li>
                  <li>
                    <strong>Daten&shy;basierte Entschei&shy;dungs&shy;findung:</strong> Treffen Sie fundierte Entschei&shy;dungen, die auf zuver&shy;l&auml;ssigen und aktuellen Informationen basieren.
                  </li>
                  <li>
                    <strong>Flexible Deployment-Optionen:</strong> W&auml;hlen Sie zwischen Cloud- oder On-Premise-Bereit&shy;stellung, um den indivi&shy;duellen Anforderungen Ihres Unter&shy;nehmens gerecht zu werden.
                  </li>
                </ul>

              </div>
            </li>
            <li>
              <div className="list-title">
                <strong>Customer Insights & Journeys</strong>
              </div>
              <ProgressiveImageCustom src={customerInsights} />
              <div className="list-description">
                Nutzen Sie KI-gestützte Ein&shy;blicke, um ein voll&shy;ständiges Bild Ihrer Kunden zu erhalten. Dynamics 365 ermöglicht
                perso&shy;nali&shy;sierte Kunden&shy;erlebnisse, stärkt die Kunden&shy;bindung und fördert das Wachs&shy;tum durch daten&shy;basierte Stra&shy;tegien.
                Analysieren Sie Ver&shy;halten, Vor&shy;lieben und Trends, um Ihre Marke&shy;ting- und Ver&shy;kaufs&shy;strategien gezielt aus&shy;zurichten.
              </div>
              <div className="list-description">
                Ver&shy;ein&shy;heit&shy;lichen Sie Ihre Ver&shy;triebs- und Marke&shy;ting&shy;teams, um Kunden besser zu ver&shy;stehen und
                maß&shy;geschnei&shy;derte Kam&shy;pagnen zu ent&shy;wickeln. Mit fort&shy;schritt&shy;licher Auto&shy;matisie&shy;rung und inte&shy;grierter Ana&shy;lytik können Sie
                Leads effi&shy;zient ver&shy;walten, Ver&shy;kaufs&shy;chancen maxi&shy;mieren und eine naht&shy;lose Customer Journey schaffen.
              </div>
            </li>

            <li>

              <div className="list-title">
                <strong>Field Service - Befähigen Sie Ihre Außen&shy;dienst&shy;teams mit Dynamics 365</strong>
              </div>
              <ProgressiveImageCustom src={fieldService} />
              <div className="list-description">

  <p>
    Unterstützen Sie Ihre Außen&shy;dienst&shy;mit&shy;arbeiter mit inno&shy;vativen Tools, die ihre Effi&shy;zienz steigern, Zeit&shy;pläne opti&shy;mieren und
    die Kunden&shy;zufriedenheit sicher&shy;stellen. Dynamics 365 bietet modernste Tech&shy;nologien und Funk&shy;tionen, um pro&shy;aktive und effek&shy;tive Dienst&shy;leistungen zu gewähr&shy;leisten.
  </p>
  <ul>
    <li>
      <strong>IoT-gestützte Ein&shy;blicke:</strong> Nutzen Sie Echt&shy;zeit&shy;daten von ver&shy;netzten Geräten, um Pro&shy;bleme früh&shy;zeitig zu erkennen und schneller zu lösen.
    </li>
    <li>
      <strong>Intelligente Routen&shy;planung:</strong> Redu&shy;zieren Sie Reise&shy;zeiten und opti&shy;mieren Sie die Einsatz&shy;planung, um Kosten zu senken und die Produk&shy;tivität zu steigern.
    </li>
    <li>
      <strong>Effizientes Ressourcen&shy;management:</strong> Ver&shy;walten Sie Personal, Werk&shy;zeuge und Mate&shy;rialien effek&shy;tiv, um Projekte termingerecht und im Rahmen des Budgets abzu&shy;schließen.
    </li>
    <li>
      <strong>Verbesserte Kunden&shy;zufriedenheit:</strong> Stellen Sie sicher, dass Ihre Kunden durch schnellere Reaktions&shy;zeiten und maß&shy;geschnei&shy;derte Lösungen zufrie&shy;den sind.
    </li>
  </ul>
</div>


            </li>
            <li>

              <div className="list-title">
                <strong>Finance & Operations - Trans&shy;formieren Sie Ihre Finanz&shy;prozesse mit Dynamics 365 Finance</strong>
              </div>
              <ProgressiveImageCustom src={finance} />
              <div className="list-description">
  <p>
    Dynamics 365 Finance ist die um&shy;fassende Lösung für modernes Finanz&shy;management.
    Diese Plattform bietet Ihnen die Werk&shy;zeuge, die Sie benötigen, um finan&shy;zielle Abläufe zu opti&shy;mieren, Kosten zu senken und
    strate&shy;gische Entschei&shy;dungen fundiert zu treffen.
  </p>
  <ul>
    <li>
      <strong>Budget&shy;verwaltung:</strong> Erstellen, über&shy;wachen und opti&shy;mieren Sie Budgets mit benutzer&shy;freundlichen Tools, die Ihnen eine präzise Kontrolle über Ihre Finanzen geben.
    </li>
    <li>
      <strong>Ertrags&shy;prognosen:</strong> Nutzen Sie KI-gestützte Vor&shy;hersagen, um Ein&shy;nahmen und Aus&shy;gaben akkurat zu pro&shy;gnostizieren und Risiken besser zu steuern.
    </li>
    <li>
      <strong>Echtzeit&shy;einblicke:</strong> Erhalten Sie aktuelle Berichte und Analysen, um fundierte Entschei&shy;dungen zu treffen und finan&shy;zielle Trans&shy;parenz im gesamten Unter&shy;nehmen zu gewährleisten.
    </li>
    <li>
      <strong>Globale Finanz&shy;abwicklung:</strong> Unter&shy;stützen Sie komplexe Anfor&shy;derungen wie Mehr&shy;währungs&shy;abwicklung, inter&shy;nationale Compliance und lokale Steuer&shy;regelungen, um auf globaler Ebene agil zu bleiben.
    </li>
    <li>
      <strong>Automati&shy;sierung und Effi&shy;zienz:</strong> Automati&shy;sieren Sie manuelle Prozesse wie Rechnungs&shy;stellung und Bank&shy;abgleiche, um Ihre Teams zu entlasten und die Genauig&shy;keit zu steigern.
    </li>
  </ul>
</div>

            </li>
          </ul>
        </section>
        <button
          className="anfrage-btn not-on-grid"
          onClick={() => handleInquiry("CRM/ERP")}
        >
          Anfrage
        </button>      </div>
    </div>
  );
}
