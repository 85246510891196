import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import emailjs from 'emailjs-com';
import './ContactForm.css'; // Import the CSS file for styling

function ContactForm() {
  const location = useLocation();
  const preselectedTopic = location.state?.preselectedTopic || '';

  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    topic: preselectedTopic,
    message: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({
    user_name: '',
    user_email: '',
    topic: '',
    message: '',
  });

  useEffect(() => {
    if (preselectedTopic) {
      setFormData((prevState) => ({
        ...prevState,
        topic: preselectedTopic,
      }));
    }
  }, [preselectedTopic]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { user_name: '', user_email: '', topic: '', message: '' };

    if (!formData.user_name.trim()) {
      newErrors.user_name = 'Bitte geben Sie Ihren Namen ein.';
      valid = false;
    }

    if (!formData.user_email.trim() || !validateEmail(formData.user_email)) {
      newErrors.user_email = 'Bitte geben Sie eine gültige Email-Adresse ein.';
      valid = false;
    }

    if (!formData.topic) {
      newErrors.topic = 'Bitte wählen Sie ein Thema aus.';
      valid = false;
    }

    if (!formData.message.trim()) {
      newErrors.message = 'Bitte geben Sie eine Nachricht ein.';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Reset specific error when the user starts typing
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission

    if (!validateForm()) {
      return;
    }

    // Generate a simple captcha challenge
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    const correctAnswer = num1 + num2;

    // Ask the user to solve the captcha
    const userAnswer = window.prompt(`Bitte lösen Sie das Captcha: ${num1} + ${num2} = ?`);

    // Check if the captcha is solved correctly
    if (parseInt(userAnswer) === correctAnswer) {
      setIsSubmitting(true);
      // Proceed with sending the email using emailjs
      emailjs
        .send('service_5vjhbv7', 'template_h7t4xcq', formData, 'Z2hQ_lZVSOpiHOHdw')
        .then(
          (result) => {
            alert('Vielen Dank für Ihre E-Mail! Wir werden Ihnen so rasch wie möglich Antworten.');
            setFormData({ user_name: '', user_email: '', topic: '', message: '' }); // Reset form fields
          },
          (error) => {
            alert('Oh mann, da lief etwas schief :(');
          }
        )
        .finally(() => setIsSubmitting(false)); // Reset submission state
    } else {
      alert('Falsche Captcha-Antwort. Bitte versuchen Sie es erneut.');
    }
  };

  return (
    <form className="email-form" onSubmit={handleSubmit}>
      <div className="form-group">
        <input
          className="input-form"
          type="text"
          name="user_name"
          placeholder="Name"
          value={formData.user_name}
          onChange={handleChange}
        />
        <div className={`error-placeholder ${errors.user_name ? 'visible' : ''}`}>
          {errors.user_name}
        </div>
      </div>

      <div className="form-group">
        <input
          className="input-form"
          type="email"
          name="user_email"
          placeholder="Email"
          value={formData.user_email}
          onChange={handleChange}
        />
        <div className={`error-placeholder ${errors.user_email ? 'visible' : ''}`}>
          {errors.user_email}
        </div>
      </div>

      <div className="form-group">
        <p>Ich interessiere mich für:</p>
        <select
          className="input-form"
          name="topic"
          value={formData.topic}
          onChange={handleChange}
        >
          <option value="">Bitte wählen Sie ein Thema aus</option>
          <option value="Microsoft Dynamics 365">Microsoft Dynamics</option>
          <option value="Google Cloud">Google Cloud</option>
          <option value="Websites und Webapps">Webseite</option>
          <option value="Datenanalyse">Datenanalyse</option>
          <option value="Datenbanken & Datenmodelle">Datenbanken & Datenmodelle</option>
          <option value="Künstliche Intelligenz">Künstliche Intelligenz</option>
          <option value="Prozessdigitalisierung">Prozessdigitalisierung</option>
          <option value="Etwas anderes">Etwas anderes</option>Prozessdigitalisierung
        </select>
        <div className={`error-placeholder ${errors.topic ? 'visible' : ''}`}>
          {errors.topic}
        </div>
      </div>

      <div className="form-group">
        <textarea
          className="input-form message"
          name="message"
          placeholder="Nachricht"
          value={formData.message}
          onChange={handleChange}
        />
        <div className={`error-placeholder ${errors.message ? 'visible' : ''}`}>
          {errors.message}
        </div>
      </div>

      {!isSubmitting && <button type="submit">Senden</button>}
    </form>
  );
}

export default ContactForm;
