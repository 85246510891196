import React, { useEffect, useRef } from "react";

import img2 from "../Media/janni.png";
import img3 from "../Media/Andrei.png";
import img4 from "../Media/tim.png";

function ContactGrid() {
  const gridRef = useRef(null);

  useEffect(() => {
    const gridElement = gridRef.current;

    if (!gridElement) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      },
      { threshold: 0.1 } // Trigger animation when 20% of the item is visible
    );

    const items = gridElement.querySelectorAll(".feature-item");
    items.forEach((item) => observer.observe(item));

    return () => observer.disconnect();
  }, []);


  const handleLearnMore = (featureName) => {
    console.log(`Learn more about: ${featureName}`);
    // Add additional logic, such as navigation or opening a modal
  };
  const handleInquiry = (featureName) => {
    console.log(`Learn more about: ${featureName}`);
    // Add additional logic, such as navigation or opening a modal
  };


  return (
    <div className="features-grid" ref={gridRef}>
      <div className="contact-tile">

        <img src={img2} className="profile-picture" />
        <h3>Janni Batsilas</h3>
        <p>Geschäftsführer</p>
      </div>

      <div className="contact-tile">

        <img src={img3} className="profile-picture" />
        <h3>Andrei Faur</h3>
        <p>Experte für Systemintegrationen und Softwareentwickler</p>
      </div>

      <div className="contact-tile">
        <img src={img4} className="profile-picture" />
        <h3>Timothy Siebeneich</h3>
        <p>Experte für CRM und ERP Systeme</p>
      </div>

    </div>
  );
}

export default ContactGrid;
