import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../Media/logo.png";
import './Menu.css'; // Import the CSS file for styling

export default function Menu() {
  const [navbar, setNavbar] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const location = useLocation(); // Get the current location

  const changeBackground = () => {
    if (window.scrollY >= 10) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeBackground);
    return () => window.removeEventListener("scroll", changeBackground);
  }, []);

  const isActive = (path) => location.pathname === path ? 'active-link' : '';

  return (
    <div className="navigation-fixed">
      <Navbar expanded={expanded} fixed='top' collapseOnSelect expand="md" className={navbar ? 'navbar active' : 'navbar'}>
        <Navbar.Brand href="/about">
          <div className="navbar-logo-and-title">
            <img
              src={logo}
              width="60"
              height="60"
              className={navbar ? 'navbar-logo d-inline-block align-middle active' : 'navbar-logo d-inline-block align-middle'}
              alt="React Bootstrap logo"
            />
            <div className="navbar-title">NextNode GmbH</div>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle onClick={() => setExpanded(!expanded)} aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <Link onClick={() => setExpanded(false)} className={`nav-links ${isActive('/about')}`} to="/about">Über Uns</Link>
            <Link onClick={() => setExpanded(false)} className={`nav-links ${isActive('/service')}`} to="/service">Dienstleistungen</Link>
            <Link onClick={() => setExpanded(false)} className={`nav-links ${isActive('/contact')}`} to="/contact">Team und Kontakt</Link>
            <Link onClick={() => setExpanded(false)} className={`nav-links ${isActive('/login')}`} to="/login">Login</Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}
