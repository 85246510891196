import React, { useEffect } from 'react';

const DynamicsMarketingForm = () => {
  // Function to dynamically load scripts
  const loadScript = (src, id) => {
    return new Promise((resolve, reject) => {
      // Check if the script is already loaded
      if (document.getElementById(id)) {
        resolve(`Script ${id} already loaded.`);
        return;
      }

      const script = document.createElement('script');
      script.src = src;
      script.id = id;
      script.async = true;
      script.onload = () => resolve(`Script ${id} loaded successfully.`);
      script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    // URLs of the Dynamics Marketing scripts
    const formLoaderScriptUrl =
      'https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.84.2007';
    const trackingScriptUrl =
      'https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.84.2007';

    // Load scripts
    loadScript(formLoaderScriptUrl, 'form-loader-script')
      .then((message) => console.log(message))
      .catch((err) => console.error(err));

    loadScript(trackingScriptUrl, 'tracking-script')
      .then((message) => console.log(message))
      .catch((err) => console.error(err));
  }, []);

  return (
    <div>
      <div data-form-block-id="2775eeca-36d8-ef11-8eea-000d3ab6a940"></div>
      <script src="https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.84.2007"></script>
      <div id="dqL25DRZ44bXP94vVLJ9drtFwQwlWDRCYb_oTGDdZumI"></div>
      <script src="https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.84.2007"></script>
      <div 
        className="d365-mkt-config" 
        style={{ display: 'none' }} 
        data-website-id="qL25DRZ44bXP94vVLJ9drtFwQwlWDRCYb_oTGDdZumI" 
        data-hostname="6fe14388935e42d5b8513078a478ecf5.svc.dynamics.com"
      ></div>
    </div>
  );
};

export default DynamicsMarketingForm;
