import React, { useEffect } from "react";
import "./Banner.css"

function Banner({ image, textTop, textBottom, textColor = "#04003b" }) {
  useEffect(() => {
    const fadeIns = document.querySelectorAll(".fade-in");

    fadeIns.forEach((el, index) => {
      el.style.animationDelay = `${index * 0.5}s`; // Staggered animation
    });
  }, []);

  return (
    <div className="banner-img-container">
      <div
        className="banner-img"
        style={{
          backgroundImage: `url('${image}')`,
        }}
      ></div>
      <div
        className="banner-overlay-text"
        style={{
          color: textColor, // Dynamically set the text color
        }}
      >
        <div className="fade-in first">{textTop}</div>
        <div className="fade-in second">{textBottom}</div>
      </div>
    </div>
  );
}

export default Banner;
