import React, { useEffect } from "react";
import img2 from "../Media/janni.png";
import img3 from "../Media/Andrei.png";
import img4 from "../Media/tim.png";
import ContactForm from "../Components/ContactForm.tsx";
import ContactGrid from "../Components/ContactGrid.tsx"; // Adjust the path based on your folder structure

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  return (
    <div className="contact">
      <ContactGrid />
      <ContactForm />
    </div>
  );
};

export default Contact;
