import React from "react";
import { useNavigate } from "react-router-dom";
import "./GenericButton.css"; // Import the provided CSS

const GenericButton = ({ text, route }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(route);
  };

  return (
    <div className="generic-btn-container">
      <button className="generic-btn" onClick={handleClick}>
        {text} &#9654;
      </button>
    </div>
  );
};

export default GenericButton;
