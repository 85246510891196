import React from 'react';
import Lottie from 'react-lottie';
import animation1 from '../Media/graph-chart-animation.json';
import animation2 from '../Media/sculpturing-animation.json';
import animation3 from '../Media/lottie-working-in-office-animation.json';
import animation4 from '../Media/lottie-network-animation.json';
import animation5 from '../Media/straight-lines.json';
import animation6 from '../Media/lottie_digitalization.json';

// Mapping animation keys to JSON data
const animations = {
  animation1,
  animation2,
  animation3,
  animation4,
  animation5,
  animation6,
};

const LottieAnimation = ({ animationKey, speed = 1 }) => {
  if (!animations[animationKey]) {
    console.error(`Animation key "${animationKey}" not found.`);
    return null; // Return null if the animationKey is invalid
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animations[animationKey], // Select animation based on key
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Lottie options={defaultOptions} speed={speed} />
    </div>
  );
};

export default LottieAnimation;
