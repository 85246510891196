import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import logo from '../Media/logo.png';
import './Footer.css'; // Your existing CSS

export default function Menu() {

  return (
    <div className='footer'>
      <div className="footer-main-container">
     
        <div className="footerc1">
          
          <div className='footer-dynamic-logo'>
            <img src={logo} width="25" height="20" className="navbar-logo-footer"/>&nbsp;NextNode GmbH
          </div>
          <div>In den Ziegelhöfen 121</div>
          <div>CH-4054 Basel</div>
          <a className="footer-mail" href="mailto: info@next-node.ch">info@next-node.ch</a>
          <div>UID/MWST CHE-177.267.627</div>
        </div>

        <div className="footerc1">
          <Link className="text-decoration-none footer-nav-hover" to="/about">| Über Uns</Link>
          <Link className="text-decoration-none footer-nav-hover" to="/service">| Dienstleistungen</Link>
          <Link className="text-decoration-none footer-nav-hover" to="/contact">| Kontakt</Link>
          <Link className="text-decoration-none footer-nav-hover" to="/login">| Login</Link>
        </div>
      


        {/* Rabbit Image with Smooth Movement */}
     
      </div>

    </div>
  );
}
