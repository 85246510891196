import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import "./App.css";
import Service from "./Page/Service.tsx";
import About from "./Page/About.tsx";
import Menu from "./Components/Menu.tsx";
import TestMenu from "./Components/TestMenu.tsx";
import Contact from "./Page/Contact.tsx";
import Footer from "./Components/Footer.tsx";
import Inquiry from "./Page/Inquiry.tsx";

import Service1_Dynamics365 from "./Page/Service1_Dynamics365.tsx";
import Service2_Web from "./Page/Service2_Web.tsx";
import Service3_GoogleCloud from "./Page/Service3_GoogleCloud.tsx";
import Service4_Process from "./Page/Service4_Process.tsx";
import Service5_AI from "./Page/Service5_AI.tsx";
import Service6_Datascience from "./Page/Service6_Datascience.tsx";
import Service7_Database from "./Page/Service7_Database.tsx";

import TEST_BT from "./Page/TEST_BT.tsx";

import ImageHosting from "./Page/ImageHosting.tsx";
import DOCSTORAGE from "./Page/DOCSTORAGE.tsx";

import TEST_GENERAL_DE from "./Page/TEST_GENERAL_DE.tsx";
import TEST_GENERAL_IT from "./Page/TEST_GENERAL_IT.tsx";
import TEST_GENERAL_FR from "./Page/TEST_GENERAL_FR.tsx";
import TEST_GENERAL_ES from "./Page/TEST_GENERAL_ES.tsx";
import TEST_GENERAL_EN from "./Page/TEST_GENERAL_EN.tsx";

import TEST_MEMBERS_DE from "./Page/TEST_MEMBERS_DE.tsx";
import TEST_MEMBERS_EN from "./Page/TEST_MEMBERS_EN.tsx";
import TEST_EVENT from "./Page/TEST_EVENT.tsx";

import TEST_MEDIA_ALL from "./Page/TEST_MEDIA_ALL.tsx";
import SUBSCRIPTION_CENTER_ALL from "./Page/SUBSCRIPTION_CENTER_ALL.tsx";

import LoginPage from "./Components/Login.tsx";
import { AuthProvider, useAuth } from "./Components/AuthProvider.tsx"; 

import ParticleBanner from './Components/ParticleBanner.tsx';

function App() {
  return (
    <AuthProvider> {/* This uses the imported AuthProvider */}
      <BrowserRouter>
        <LayoutBasedOnRoute />
      </BrowserRouter>
    </AuthProvider>
  );
}

function LayoutBasedOnRoute() {
  let location = useLocation();
  const showMainLayout = !location.pathname.startsWith("/TEST_BT");
  const { isLoggedIn } = useAuth(); // Use the auth hook to check if the user is logged in

  if (!isLoggedIn && location.pathname.startsWith("/TEST_BT")) {
    localStorage.setItem('postLoginRedirectPath', window.location.pathname);
    return <Navigate to="/login" replace />;
  }

  return (
    <>
      {showMainLayout ? <ParticleBanner /> : null}
      {showMainLayout ? <Menu /> : <TestMenu />}
      <Routes>
        <Route path="/" element={<Navigate replace to="/about" />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/contact" element={<Contact />} />Service1_Dynamics365
        <Route path="/inquiry" element={<Inquiry />} />

        <Route path="/Microsoft Dynamics 365" element={<Service1_Dynamics365 />} />
        <Route path="/Websites und Webapps" element={<Service2_Web />} />
        <Route path="/Google Cloud" element={<Service3_GoogleCloud />} />
        <Route path="/Prozessdigitalisierung" element={<Service4_Process />} />
        <Route path="/Künstliche Intelligenz" element={<Service5_AI />} />
        <Route path="/Datenanalyse" element={<Service6_Datascience />} />
        <Route path="/Datenbanken & Datenmodelle" element={<Service7_Database />} />

        <Route path="/TEST_BT" element={<TEST_BT key={window.location.pathname} />}>

          <Route path="DOCSTORAGE" element={<DOCSTORAGE />} />
          <Route path="ImageHosting" element={<ImageHosting />} />
          
          <Route path="de/TEST_GENERAL_DE" element={<TEST_GENERAL_DE />} />
          <Route path="it/TEST_GENERAL_IT" element={<TEST_GENERAL_IT />} />
          <Route path="fr/TEST_GENERAL_FR" element={<TEST_GENERAL_FR />} />
          <Route path="es/TEST_GENERAL_ES" element={<TEST_GENERAL_ES />} />
          <Route path="en/TEST_GENERAL_EN" element={<TEST_GENERAL_EN />} />

          <Route path="de/TEST_MEMBERS_DE" element={<TEST_MEMBERS_DE />} />
          <Route path="en/TEST_MEMBERS_EN" element={<TEST_MEMBERS_EN />} />
          <Route path="TEST_EVENT" element={<TEST_EVENT />} />

          <Route path="de/TEST_MEDIA_DE" element={<TEST_MEDIA_ALL />} />
          <Route path="it/TEST_MEDIA_IT" element={<TEST_MEDIA_ALL />} />
          <Route path="fr/TEST_MEDIA_FR" element={<TEST_MEDIA_ALL />} />
          <Route path="es/TEST_MEDIA_ES" element={<TEST_MEDIA_ALL />} />
          <Route path="en/TEST_MEDIA_EN" element={<TEST_MEDIA_ALL />} />

          <Route path="de/SUBSCRIPTION_CENTER_ALL" element={<SUBSCRIPTION_CENTER_ALL />} />
          <Route path="it/SUBSCRIPTION_CENTER_ALL" element={<SUBSCRIPTION_CENTER_ALL />} />
          <Route path="fr/SUBSCRIPTION_CENTER_ALL" element={<SUBSCRIPTION_CENTER_ALL />} />
          <Route path="es/SUBSCRIPTION_CENTER_ALL" element={<SUBSCRIPTION_CENTER_ALL />} />
          <Route path="en/SUBSCRIPTION_CENTER_ALL" element={<SUBSCRIPTION_CENTER_ALL />} />

        </Route>
        <Route path="/login" element={<LoginPage />} />
      </Routes>
      {showMainLayout ? <Footer /> : null}
    </>
  );
}

export default App;
