import React, { useEffect } from "react";
import InquiryForm from "../Components/InquiryForm.tsx";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  return (
    <div className="contact">
      <InquiryForm />
    </div>
  );
};

export default Contact;
