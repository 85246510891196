import React, { useEffect } from "react";
import cloudImage from "../Media/service3_google_cloud.jpg";
import computeImage from "../Media/service3_google_compute.jpg";
import databaseImage from "../Media/service3_google_database.avif";
import aiImage from "../Media/service3_google_ai.jpg";
import securityImage from "../Media/service3_google_security.jpg";
import Banner from '../Components/Banner.tsx';
import { useNavigate } from "react-router-dom";
import ProgressiveImageCustom from "../Components/ProgressiveImageCustom.tsx";

export default function GoogleCloudServices() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    const items = document.querySelectorAll(".services-list li");
    items.forEach((item) => observer.observe(item));

    return () => observer.disconnect();
  }, []);

  const handleInquiry = (featureName) => {
    navigate("/inquiry", { state: { preselectedTopic: featureName } });
  };

  return (
    <div className="service">
      <div className="page-content">
        <Banner
          image={cloudImage}
          textTop="Google Cloud Services"
          textBottom="Leistungsstarke Cloud-Lösungen für Ihr Unternehmen"
          textColor="#ffffff"
        />
        
        <section className="it-partner-section">
  <h1>Unsere Google Cloud Dienst&shy;leistungen</h1>
  <p>
    Mit Google Cloud bieten wir Ihnen hoch&shy;moderne Cloud-Lösungen, die Skalier&shy;barkeit, Sicherheit
    und Effizienz vereinen. Egal ob Sie Ihre Infra&shy;struktur moder&shy;nisieren, Daten&shy;banken verwalten
    oder KI-gestützte Lösungen imple&shy;mentieren möchten – wir unter&shy;stützen Sie in jedem Schritt.
  </p>

  <ul className="services-list">
    <li>
      <div className="list-title">
        <strong>Compute Services - Skalier&shy;bare und leistungs&shy;starke Rechen&shy;ressourcen</strong>
      </div>
      <ProgressiveImageCustom src={computeImage} />
      <div className="list-description">
        <p>
          Wir helfen Ihnen bei der Einrichtung und Optimierung von Google Compute Engine,
          Kubernetes Engine und Cloud Functions. Ob virtuelle Maschinen, container&shy;isierte
          Workloads oder server&shy;lose Architekturen – wir sorgen für eine effiziente Umsetzung.
          Unsere Lösungen ermöglichen eine nahtlose Skalierung und optimierte Performance für Ihre Workloads.
        </p>
        <p>
          Darüber hinaus unter&shy;stützen wir Sie bei der Automati&shy;sierung und Verwaltung Ihrer Infra&shy;struktur
          mit Terraform, Ansible und Deployment-Pipelines. Dies gewähr&shy;leistet Effizienz, Wiederhol&shy;barkeit
          und Stabilität Ihrer Cloud-Umgebung.
        </p>
      </div>
    </li>

    <li>
      <div className="list-title">
        <strong>Daten&shy;banken & Speicher - Zuver&shy;lässige und flexible Daten&shy;verwaltung</strong>
      </div>
      <ProgressiveImageCustom src={databaseImage} />
      <div className="list-description">
        <p>
          Unsere Experten unter&shy;stützen Sie bei der Imple&shy;mentierung von Google Cloud SQL,
          Firestore, BigQuery und Cloud Storage. Ob relationale Daten&shy;banken, NoSQL oder Data Warehousing –
          wir finden die optimale Lösung für Ihre Anfor&shy;derungen.
        </p>
        <p>
          Zusätzlich helfen wir Ihnen, Ihre Daten&shy;bank-Workloads zu optimieren, automatische Backups
          einzu&shy;richten und eine effiziente Daten&shy;replikation zu gewähr&shy;leisten. Unsere Architekturen
          sind darauf ausgelegt, Ausfall&shy;zeiten zu mini&shy;mieren und höchste Verfüg&shy;barkeit zu garan&shy;tieren.
        </p>
      </div>
    </li>

    <li>
      <div className="list-title">
        <strong>Künstliche Intelli&shy;genz & Machine Learning - Intelligente und daten&shy;getriebene Anwendungen</strong>
      </div>
      <ProgressiveImageCustom src={aiImage} />
      <div className="list-description">
        <p>
          Nutzen Sie Google Cloud AI, Vertex AI und AutoML zur Imple&shy;mentierung intelligenter Anwendungen.
          Von Bilder&shy;kennung über Natural Language Processing bis hin zu maßge&shy;schneiderten ML-Modellen –
          wir helfen Ihnen, KI optimal in Ihre Geschäfts&shy;prozesse zu integrieren.
        </p>
        <p>
          Unsere ML-Experten entwickeln und trainieren Modelle, die speziell auf Ihre geschäft&shy;lichen Anfor&shy;derungen
          zugeschnitten sind. Mit Tools wie TensorFlow und PyTorch bringen wir leistungs&shy;starke Lösungen in die Produktion.
        </p>
      </div>
    </li>

    <li>
      <div className="list-title">
        <strong>Sicherheit & Netz&shy;werke - Sichere und robuste Cloud-Infra&shy;strukturen</strong>
      </div>
      <ProgressiveImageCustom src={securityImage} />
      <div className="list-description">
        <p>
          Mit Google Cloud Security, Identity & Access Management (IAM) und Netzwerk&shy;diensten
          gewährleisten wir den Schutz Ihrer Anwendungen und Daten. Wir imple&shy;mentieren Sicherheits&shy;lösungen,
          Firewalls und Richt&shy;linien, um höchste Standards zu erfüllen.
        </p>
        <p>
          Wir unter&shy;stützen Sie auch bei der Imple&shy;mentierung von Zero-Trust-Strategien, Sicherheits&shy;überwachungen
          und der Einhaltung regula&shy;torischer Anfor&shy;derungen wie DSGVO und ISO 27001.
        </p>
      </div>
    </li>
  </ul>
</section>


        <button
          className="anfrage-btn not-on-grid"
          onClick={() => handleInquiry("Google Cloud")}
        >
          Anfragen
        </button>
      </div>
    </div>
  );
}
