import React, { useEffect } from "react";
import automationImage from "../Media/service4_process_automation.avif";
import bannerImage from "../Media/prozessdigitalisierung_service.jpg";
import integrationImage from "../Media/service4_process_integration.jpg";
import analyticsImage from "../Media/service4_process_analytics.jpg";
import Banner from '../Components/Banner.tsx';
import { useNavigate } from "react-router-dom";
import ProgressiveImageCustom from "../Components/ProgressiveImageCustom.tsx";

export default function ProcessDigitalization() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    const items = document.querySelectorAll(".services-list li");
    items.forEach((item) => observer.observe(item));

    return () => observer.disconnect();
  }, []);

  const handleInquiry = (featureName) => {
    navigate("/inquiry", { state: { preselectedTopic: featureName } });
  };

  return (
    <div className="service">
      <div className="page-content">
        <Banner
          image={bannerImage}
          textTop="Prozessdigitalisierung"
          textBottom="Effiziente, digitale Prozesse für Ihr Unternehmen"
          textColor="#ffffff"
        />
<section className="it-partner-section">
  <h1>Digitale Trans&shy;formation für KMU – Effizient, Skalier&shy;bar, Zukunfts&shy;sicher</h1>
  <p>
    Der digitale Wandel ist keine Option mehr – er ist eine Notwendig&shy;keit. Unternehmen, die sich nicht anpassen, 
    laufen Gefahr, im Wettbewerb zurückzufallen. Gerade für kleine und mittel&shy;ständische Unternehmen (KMU) 
    bietet die Digitali&shy;sierung enorme Chancen: effizientere Abläufe, auto&shy;matisierte Workflows und fundierte, 
    daten&shy;basierte Entschei&shy;dungen. 
  </p>
  <p>
    Unser Team aus Experten unter&shy;stützt Sie dabei, Prozesse zu modernisieren, Daten sinnvoll zu nutzen und 
    Ihre IT-Infrastruktur zu opti&shy;mieren – mit maßgeschneiderten Lösungen für Ihr Unternehmen. 
  </p>

  <ul className="services-list">
    <li>
      <div className="list-title">
        <strong>Automati&shy;sierung & Workflow-Opti&shy;mierung - Intelligente Prozesse für maximale Effizienz</strong>
      </div>
      <ProgressiveImageCustom src={automationImage} />
      <div className="list-description">
        <p>
          Zeit&shy;raubende, manuelle Abläufe gehören der Vergangen&shy;heit an. Mit smarten Automati&shy;sierungs&shy;lösungen wie Robotic Process Automation (RPA), 
          KI-gestützter Prozess&shy;steuerung und Integrations&shy;plattformen opti&shy;mieren wir Ihre Workflows, eliminieren Fehler&shy;quellen 
          und steigern Ihre Produkti&shy;vität. 
        </p>
        <p>
          Besonders profitieren Branchen wie Finanzen, Gesundheits&shy;wesen, Logistik und Einzelhandel von auto&shy;matisierten Abläufen, 
          die repetitive Aufgaben reduzieren und Ressourcen effizienter nutzen.
        </p>
      </div>
    </li>

    <li>
      <div className="list-title">
        <strong>System&shy;integration & Daten&shy;management - Nahtlose IT-Land&shy;schaften für maximale Performance</strong>
      </div>
      <ProgressiveImageCustom src={integrationImage} />
      <div className="list-description">
        <p>
          Daten-Silos und ineffi&shy;ziente Systeme bremsen Ihr Unternehmen aus. Wir vernetzen Ihre bestehenden IT-Strukturen mit modernen 
          Integrations&shy;technologien wie API-Management, Middleware und Microservices, um einen reibungs&shy;losen Datenfluss zu gewährleisten. 
        </p>
        <p>
          Unsere Lösungen verbessern nicht nur die Effizienz, sondern sorgen auch für höhere Daten&shy;sicherheit und Compliance 
          (z. B. DSGVO, ISO 27001). Wir helfen Ihnen, daten&shy;getriebene Geschäfts&shy;modelle zu entwickeln, 
          indem wir eine solide Basis für Ihre IT-Infra&shy;struktur schaffen.
        </p>
      </div>
    </li>

    <li>
      <div className="list-title">
        <strong>Daten&shy;analyse & Smarte Entscheidungs&shy;findung - Daten nutzen – Zukunft gestalten</strong>
      </div>
      <ProgressiveImageCustom src={analyticsImage} />
      <div className="list-description">
        <p>
          Daten sind das neue Gold – aber nur, wenn sie richtig genutzt werden. Wir helfen Ihnen, mit BI-Tools wie Google Data Studio, Power BI 
          und KI-gestützter Analyse wertvolle Erkennt&shy;nisse zu gewinnen, Muster zu erkennen und daten&shy;getriebene Entscheidungen zu treffen. 
        </p>
        <p>
          Unsere fort&shy;schrittlichen Analyse&shy;lösungen umfassen Predictive Analytics, Machine Learning und Echtzeit&shy;monitoring, 
          um Trends frühzeitig zu erkennen und proaktive Geschäfts&shy;strategien zu entwickeln. So wird Ihr Unternehmen 
          agiler, voraus&shy;schauender und konkur&shy;renz&shy;fähiger.
        </p>
      </div>
    </li>
  </ul>
</section>


        <button
          className="anfrage-btn not-on-grid"
          onClick={() => handleInquiry("Prozessdigitalisierung")}
        >
          Anfragen
        </button>
      </div>
    </div>
  );
}
