import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import img3 from "../Media/img-projects-5.jpeg";
import img5 from "../Media/google_cloud_service.jpg";
import img6 from "../Media/service6_data_banner.jpg";
import img8 from "../Media/dynamics_service.png";
import img9 from "../Media/data_service.png";
import img12 from "../Media/ki_service.jpg";
import img13 from "../Media/prozessdigitalisierung_service.jpg";


import "./ServiceGrid.css";

import ProgressiveImageCustom from "../Components/ProgressiveImageCustom.tsx";

function ServiceGrid() {
  const [filter, setFilter] = useState("All");
  const gridRef = useRef(null);
  const navigate = useNavigate();

  const services = [
    {
      title: "Microsoft Dynamics 365",
      description:
        "Optimieren Sie Ihre Geschäftsprozesse mit Microsoft CRM und ERP Systemen. Wir sind Ihr Partner bei der Umsetzung von Komplettlösungen vom Datenmodell über maßgeschneiderte Erweiterungen und Integrationen in die bestehende Systemlandschaft.",
      img: img8,
      category: "Microsoft Dynamics 365",
      key: "Microsoft Dynamics 365",
    },
    {
      title: "Websites und Webapps",
      description:
        "Wir entwickeln Ihre Website, Web-Apps oder Webshop. Von der Gestaltung des Frontends bis zur Umsetzung erweiterter Funktionen, etwa Benutzeranmeldungen oder die Integration von Systemen.",
      img: img3,
      category: "Websites und Webapps",
      key: "Websites und Webapps",
    },
    {
      title: "Google Cloud",
      description:
        "Ob Google Cloud Deployments, Cloud Functions, Chrome Extensions oder Apps Script, wir sind Ihr Partner im Google-Ökosystem. Von der Automatisierung wiederkehrender Aufgaben bis zur Entwicklung maßgeschneiderter Anwendungen bieten wir umfassende Expertise für Ihre Anforderungen.",
      img: img5,
      category: "Google Cloud",
      key: "Google Cloud",
    },
    {
      title: "Prozessdigitalisierung",
      description:
        "Transformieren Sie Ihre analogen Arbeitsabläufe in effiziente, digitale Prozesse. Mit maßgeschneiderten Lösungen ermöglichen wir papierlose Abläufe, optimierte Workflows und eine reibungslose Zusammenarbeit zwischen Teams und Abteilungen.",
      img: img13,
      category: "Prozessdigitalisierung",
      key: "Prozessdigitalisierung",
    },
    {
      title: "Künstliche Intelligenz",
      description:
        "Entdecken Sie die Zukunft mit maßgeschneiderten KI-Lösungen. Von Chatbots über Bild- und Sprachverarbeitung bis hin zu Predictive Analytics entwickeln wir intelligente Systeme, die Ihre Geschäftsprozesse optimieren und neue Möglichkeiten eröffnen.",
      img: img12,
      category: "Künstliche Intelligenz",
      key: "Künstliche Intelligenz",
    },

    {
      title: "Datenanalyse",
      description:
        "Nutzen Sie das Potenzial Ihrer Daten, um fundierte Entscheidungen zu treffen. Wir bieten individuelle Data-Science-Lösungen, von Datenanalyse und Visualisierung bis hin zur Entwicklung maßgeschneiderter Machine-Learning-Modelle.",
      img: img6,
      category: "Datenanalyse",
      key: "Datenanalyse",
    },
    {
      title: "Datenbanken & Datenmodelle",
      description:
        "Basis für datengetriebene Entscheidungen sind eine qualitative Datenbasis und gute Datenmodelle. Wir entwickeln skalierbare Lösungen, die Ihre Datenstrukturen klar und zugänglich gestalten.",
      img: img9,
      category: "Datenbanken & Datenmodelle",
      key: "Datenbanken & Datenmodelle",
    },

  ];

  useEffect(() => {
    const gridElement = gridRef.current;

    if (!gridElement) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      },
      { threshold: 0.2 }
    );

    const items = gridElement.querySelectorAll(".service-item");
    items.forEach((item) => observer.observe(item));

    return () => observer.disconnect();
  }, [filter]); // Depend on filter to refresh visibility classes on updates

  const handleLearnMore = (featureName) => {
    navigate(`/${featureName}`, { state: { preselectedTopic: featureName } });
  };

  const handleInquiry = (featureName) => {
    navigate("/inquiry", { state: { preselectedTopic: featureName } });
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const filteredServices =
    filter === "All"
      ? services
      : services.filter((service) => service.category === filter);

  return (
    <div>
      <div className="filter-container">
        <label htmlFor="filter">Filter:</label>
        <select id="filter" value={filter} onChange={handleFilterChange}>
          <option value="All">Alle</option>
          <option value="Microsoft Dynamics 365">CRM/ERP</option>
          <option value="Websites und Webapps">Web Development</option>
          <option value="Google Cloud">Google Cloud</option>
          <option value="Prozessdigitalisierung">Prozessdigitalisierung</option>
          <option value="Künstliche Intelligenz">Künstliche Intelligenz</option>
          <option value="Datenanalyse">Datenanalyse</option>
          <option value="Datenbanken & Datenmodelle">Datenbanken & Datenmodelle</option>
        </select>
      </div>

      <div className="service-grid" ref={gridRef}>
        {filteredServices.map((service) => (
          <div className="service-item" key={service.key}>
            <p className="service-item-title">
              <b>{service.title}</b>
            </p>
            <ProgressiveImageCustom src={service.img} />
            <p className="service-item-description">{service.description}</p>
            <div className="button-group">
              <button
                className="anfrage-btn"
                onClick={() => handleInquiry(service.key)}
              >
                Anfrage
              </button>
              <button
                className="learn-more-btn"
                onClick={() => handleLearnMore(service.key)}
              >
                Mehr &#9654;
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ServiceGrid
